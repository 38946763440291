/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
}

export const sidebarSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    show: (state, { payload }) => {
      state.visible = { ...payload }
    },
  },
})

export const { show } = sidebarSlice.actions
export default sidebarSlice.reducer
