/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  components: [],
  not_included: [],
}

export const productionSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialValue },
  reducers: {
    setProduction: (state, action) => {
      state.visible = action.payload
    },
  },
})

export const { setProduction } = productionSlice.actions
export default productionSlice.reducer
