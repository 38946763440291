/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { readData: false, id:null }

export const flagRoleSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    readData: (state, { payload }) => {
      state.visible = { ...payload }
    },

    dataId: (state, { payload }) => {
      state.visible = { ...payload }
    },
  },
})

export const { readData,dataId } = flagRoleSlice.actions

export default flagRoleSlice.reducer
