/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  exists: false,
}

export const passwordSlice = createSlice({
  name: 'exists',
  initialState: { exists: initialState },
  reducers: {
    setHeaderPassword: (state, { payload }) => {
      state.exists = { ...payload }
    },
  },
})

export const { setHeaderPassword } = passwordSlice.actions
export default passwordSlice.reducer
