/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = { show: false }

const addModalSlice = createSlice({
  name: 'visible',
  initialState:  initialState  ,

  reducers: {
    showAddModal: (state) => {
      state.show = !state.show
    },
  },
})

export const { showAddModal } = addModalSlice.actions

export default addModalSlice.reducer

