import { configureStore } from '@reduxjs/toolkit'

import setReducer from './redux/reducers/sidebar/setReducer'
import showModalReducer from './redux/reducers/showModal'
import showModalSavedReduce from './redux/reducers/showModal/showSavedModal'
import DeleteModalReducer from './redux/reducers/showModal/DeleteSlice'
import AddModalReducer from './redux/reducers/showModal/showSavedModal'
import productionReducer from './redux/reducers/production/productionSlice'
import passwordReducer from './redux/reducers/headerPassword/passwordSlice'

import navigationReducer from './redux/reducers/RolesReducer/RolesSlice'
import dataForEditRoleFlagReducer from './redux/reducers/StorageDataForEditRoleReducer/StorageDataForEditRoleReducerFlagSlice'

const store = configureStore({
  reducer: {
    sidebar: setReducer,
    modal: showModalReducer,
    ModalSaved: showModalSavedReduce,
    deleteModalReducer: DeleteModalReducer,
    addModalReducer: AddModalReducer,
    production: productionReducer,
    password: passwordReducer,

    nav: navigationReducer,
    dataFlag: dataForEditRoleFlagReducer,
  },
})
export default store
