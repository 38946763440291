/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  showModal1: false,
}
export const modalSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialValue },
  reducers: {
    show: (state, action) => {
      state.visible = action.payload
    },
  },
})

export const { show } = modalSlice.actions
export default modalSlice.reducer
